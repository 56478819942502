// src/components/HomePage.js
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import image1 from "../images/mainslider/1.jpg";
import image2 from "../images/mainslider/2.jpg";
import image3 from "../images/mainslider/3.jpg";
import image4 from "../images/mainslider/4.jpg";
import image5 from "../images/mainslider/5.jpg";
import image6 from "../images/mainslider/6.jpg";
import image7 from "../images/mainslider/7.jpg";
import image8 from "../images/mainslider/8.jpg";
import pr1 from "../images/mainslider/projectslider/pr1.jpg";
import pr2 from "../images/mainslider/projectslider/pr2.jpg";
import pr3 from "../images/mainslider/projectslider/pr3.jpg";
import pr4 from "../images/mainslider/projectslider/pr4.jpg";
import pr5 from "../images/mainslider/projectslider/pr5.jpg";
import pr6 from "../images/mainslider/projectslider/pr6.jpg";
import pr7 from "../images/mainslider/projectslider/pr7.jpg";
import pr8 from "../images/mainslider/projectslider/pr8.jpg";
import videoSource from "../video/v1.mp4";
import aboutImage1 from "../images/mainslider/NBF 6.jpg";
import aboutImage2 from "../images/mainslider/projectslider/big.jpg";
import smallVideo from "../video/small_video.mp4";
import offerImage from "../images/mainslider/large.jpg";
import businessImage from "../images/mainslider/business.jpg";
import educationImage from "../images/mainslider/higher-education.jpg";
import livingImage from "../images/mainslider/living.jpg";
import municipalImage from "../images/mainslider/municipal.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomePage.css";

const HomePage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  const handleSearchIconClick = (link) => {
    navigate(link);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    beforeChange: (current, next) => setSelectedImage(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
  ];

  const projects = [
    { image: pr1, alt: "Project 1", link: "/ProjectPage" },
    { image: pr2, alt: "Project 2", link: "/ProjectPage" },
    { image: pr3, alt: "Project 3", link: "/ProjectPage" },
    { image: pr4, alt: "Project 4", link: "/ProjectPage" },
    { image: pr5, alt: "Project 5", link: "/ProjectPage" },
    { image: pr6, alt: "Project 6", link: "/ProjectPage" },
    { image: pr7, alt: "Project 7", link: "/ProjectPage" },
    { image: pr8, alt: "Project 8", link: "/ProjectPage" },
  ];

  return (
    <div className="home-page">
      <div className="hero-container">
        <img
          src={images[currentImageIndex]}
          alt={`Hero ${currentImageIndex + 1}`}
          className="hero-image"
        />
      </div>
      <div className="featured-projects-2">
        <h2>Featured Projects</h2>
        <Slider {...settings}>
          {projects.map((project, index) => (
            <div
              key={index}
              className={`slide ${selectedImage === index ? "selected" : ""}`}
            >
              <div
                className={`image-container ${
                  selectedImage === index ? "selected-container" : ""
                }`}
              >
                <img
                  src={project.image}
                  alt={project.alt}
                  className={`clipped-image ${
                    selectedImage === index ? "cropped" : ""
                  }`}
                />
                {selectedImage === index && (
                  <Link to={`/ProjectPage${index + 1}`}>
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                  </Link>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <section className="hero-section">
        <div className="hero-section-content">
          <h1>Creating Spaces that Work</h1>
          <p>
            Whether you’re working on simple renovations or a large-scale new
            facility, our dedicated staff of architects and engineers are here
            to offer professional and experienced advice. Our team gives you the
            care and attention you deserve, with solution-based feedback in all
            phases of the project and field observation on all types of
            construction projects. Quality shouldn’t come at a price. No matter
            what your vision, we’ll work with you to create functional and
            cost-effective designs that work for you and your community.
          </p>
        </div>
        <div className="hero-section-video">
          <video
            src={videoSource}
            alt="Nature Pathway"
            autoPlay
            muted
            loop
            playsInline
          ></video>
        </div>
      </section>
      <section className="about-us">
        <div className="about-us-content">
          <Link to="/about">
            <button>About Us</button>
          </Link>
        </div>
        <div className="about-us-images">
          <img src={aboutImage1} alt="Building" />
        </div>
      </section>
      <section className="additional-images">
        <div className="additional-images-wrapper">
          <img className="large-image" src={aboutImage2} alt="Living Area" />
          <video className="small-image" src={smallVideo} autoPlay loop muted />
        </div>
      </section>
      <div className="Section-sc-1vjgz5n-0 gMfwoD">
        <div className="Container-sc-1uyjznq-0 eBZEyY Grid-sc-1gc00t8-0 fakLAl">
          <div className="Grid__Item-sc-1gc00t8-1 CenteredContent__ContentWrap-sc-yfj9nq-0 lbvRqf">
            <h3 className="H__H1-sc-be5gk5-0 HomeIndustries__IndustryHeading-sc-12x8pk9-1 eGDaCZ">
              What We Offer
            </h3>
            <p>
              We at KOTTOOR master this art with our passion & creativity
              towards our fitouts. Every project in interiors is completely
              different from the other. Although logically there are only wall,
              ceiling and floor in every single project but when you look at it
              with an eye of detail it is two different world. The colors
              combinations, finishes, design, corporate identity, fit out style,
              timelines, budgets, building regulation, local authority
              regulation make it different.
            </p>
          </div>
        </div>
        <div className="ImageContainer">
          <img src={offerImage} alt="What We Offer" className="OfferImage" />
        </div>
      </div>
      <div className="Container-sc-1uyjznq-0 eBZEyY Grid-sc-1gc00t8-0 HomeIndustries__IndustriesGrid-sc-12x8pk9-5 eavKAV">
        <div className="Grid__Item-sc-1gc00t8-1 HomeIndustries__IndustryItem-sc-12x8pk9-6 hzBbuM">
          <div className="AngledImg__StyledAngledImg-sc-l04nmq-0 eedwbc HomeIndustries__IndustryImg-sc-12x8pk9-7 iexjdx">
            <div className="RatioImg__Wrapper-sc-8qesjk-1 fZxNrn">
              <img
                alt="Business"
                className="RatioImg__Img-sc-8qesjk-0 dwEpAz"
                src={businessImage}
              />
            </div>
          </div>
          <div className="HomeIndustries__IndustryContent-sc-12x8pk9-8 jKMlSJ">
            <h4 className="H__H4-sc-be5gk5-2 StwfE">Business</h4>
            <p className="HomeIndustries__IndustryCopy-sc-12x8pk9-9 fywiye">
              Whether you need an office, a storefront or an airy workspace,
              we'll create a functional design that works for you and your team.
              From spaces that allow for easy movement to designs that make
              customers feel more at home, our team puts innovation and
              creativity at the forefront of every project.
            </p>
            <a
              aria-label="Business"
              className="StyledLink-sc-zurfbq-0 vQgHw"
              href="/business"
            >
              learn more
            </a>
          </div>
        </div>
        <div className="Grid__Item-sc-1gc00t8-1 HomeIndustries__IndustryItem-sc-12x8pk9-6 hzBbuM">
          <div className="AngledImg__StyledAngledImg-sc-l04nmq-0 eedwbc HomeIndustries__IndustryImg-sc-12x8pk9-7 iexjdx">
            <div className="RatioImg__Wrapper-sc-8qesjk-1 fZxNrn">
              <img
                alt="Higher Education"
                className="RatioImg__Img-sc-8qesjk-0 dwEpAz"
                src={educationImage}
              />
            </div>
          </div>
          <div className="HomeIndustries__IndustryContent-sc-12x8pk9-8 jKMlSJ">
            <h4 className="H__H4-sc-be5gk5-2 StwfE">Higher Education</h4>
            <p className="HomeIndustries__IndustryCopy-sc-12x8pk9-9 fywiye">
              We strive to elevate the campus experience. With designs that
              inspire learning and collaboration, our academic environments help
              shape the minds of future generations.
            </p>
            <a
              aria-label="Higher Education"
              className="StyledLink-sc-zurfbq-0 vQgHw"
              href="/higher-education"
            >
              learn more
            </a>
          </div>
        </div>
        <div className="Grid__Item-sc-1gc00t8-1 HomeIndustries__IndustryItem-sc-12x8pk9-6 hzBbuM">
          <div className="AngledImg__StyledAngledImg-sc-l04nmq-0 eedwbc HomeIndustries__IndustryImg-sc-12x8pk9-7 iexjdx">
            <div className="RatioImg__Wrapper-sc-8qesjk-1 fZxNrn">
              <img
                alt="Living"
                className="RatioImg__Img-sc-8qesjk-0 dwEpAz"
                src={livingImage}
              />
            </div>
          </div>
          <div className="HomeIndustries__IndustryContent-sc-12x8pk9-8 jKMlSJ">
            <h4 className="H__H4-sc-be5gk5-2 StwfE">Living</h4>
            <p className="HomeIndustries__IndustryCopy-sc-12x8pk9-9 fywiye">
              Safety and comfort are at the forefront of our living
              environments. With designs that enhance your community and provide
              ample space for studying, play time or visiting a loved one, our
              housing and senior living spaces make residents of all ages feel
              at home.
            </p>
            <a
              aria-label="Living"
              className="StyledLink-sc-zurfbq-0 vQgHw"
              href="/living"
            >
              learn more
            </a>
          </div>
        </div>
        <div className="Grid__Item-sc-1gc00t8-1 HomeIndustries__IndustryItem-sc-12x8pk9-6 hzBbuM">
          <div className="AngledImg__StyledAngledImg-sc-l04nmq-0 eedwbc HomeIndustries__IndustryImg-sc-12x8pk9-7 iexjdx">
            <div className="RatioImg__Wrapper-sc-8qesjk-1 fZxNrn">
              <img
                alt="Municipal/Industrial"
                className="RatioImg__Img-sc-8qesjk-0 dwEpAz"
                src={municipalImage}
              />
            </div>
          </div>
          <div className="HomeIndustries__IndustryContent-sc-12x8pk9-8 jKMlSJ">
            <h4 className="H__H4-sc-be5gk5-2 StwfE">Municipal/Industrial</h4>
            <p className="HomeIndustries__IndustryCopy-sc-12x8pk9-9 fywiye">
              When it comes to productivity, a well-designed space is key. Our
              approach is simple - we'll work with you to design a space that
              will optimize operations and help you succeed safely, efficiently
              and effectively.
            </p>
            <a
              aria-label="Municipal/Industrial"
              className="StyledLink-sc-zurfbq-0 vQgHw"
              href="/municipal-industrial"
            >
              learn more
            </a>
          </div>
        </div>
      </div>

      <section className="our-clients">
        <h2 className="clients-heading">Our Clients</h2>
        <div className="clients-row">
          <span className="client-name">Superior Extrusions Inc.</span>
          <span className="client-name">Embers Credit Union</span>
          <span className="client-name">Lincoln Consolidated Schools</span>
          <span className="client-name highlight">The Delft Bistro</span>
        </div>
        <div className="clients-row">
          <span className="client-name highlight">Brighton Area Schools</span>
          <span className="client-name">Barrel + Beam</span>
          <span className="client-name">Cliffs Natural Resources</span>
        </div>
        <div className="clients-row">
          <span className="client-name">Rippling River Resort Campground</span>
          <span className="client-name">Hannahville Indian Community</span>
          <span className="client-name highlight">Meridian Public Schools</span>
          <span className="client-name">George Young Recreation Complex</span>
        </div>
      </section>
    </div>
  );
};

export default HomePage;

window.addEventListener("beforeunload", () => {
  document.body.classList.add("fade-out");
});
