import React, { useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import heroImage from "../images/mainslider/5.jpg";

import join from "../images/projects/Project6/1 - Copy.jpg";
import "./ServicesPage.css";



// Import images for projects
import project1Image2 from "../images/projects/Project1/2.jpg";
import project1Image5 from "../images/projects/Project1/5.jpg";
import project2Image2 from "../images/projects/Project2/2.jpg";
import project2Image4 from "../images/projects/Project2/4.jpg";
import project2Image7 from "../images/projects/Project2/7.jpg";
import project3Image3 from "../images/projects/Project3/3.jpg";
import project3Image4 from "../images/projects/Project3/4.jpg";
import project3Image5 from "../images/projects/Project3/5.jpg";
import project3Image7 from "../images/projects/Project3/7.jpg";
import project3Image6 from "../images/projects/Project3/6.jpg";
import project3Image9 from "../images/projects/Project3/9.jpg";
import project4Image2 from "../images/projects/Project4/2.jpg";
import project4Image4 from "../images/projects/Project4/4.jpg";
import project5Image3 from "../images/projects/Project5/3.jpg";
import project5Image2 from "../images/projects/Project5/2.jpg";
import project5Image6 from "../images/projects/Project5/6.jpg";
import project5Image7 from "../images/projects/Project5/7.jpg";
import project5Image9 from "../images/projects/Project5/9.jpg";
import project6Image4 from "../images/projects/Project6/4.jpg";
import project6Image5 from "../images/projects/Project6/5.jpg";
import project6Image6 from "../images/projects/Project6/6.jpg";
import project6Image7 from "../images/projects/Project6/7.jpg";
import project7Image2 from "../images/projects/Project7/2.jpg";
import project7Image5 from "../images/projects/Project7/5.jpg";
import project8Image9 from "../images/projects/Project8/9.jpg";
import project8Image6 from "../images/projects/Project8/6.jpg";
import project8Image5 from "../images/projects/Project8/5.jpg";
import project8Image4 from "../images/projects/Project8/4.jpg";
import project8Image3 from "../images/projects/Project8/3.jpg";
import project8Image2 from "../images/projects/Project8/2.jpg";

const projects = [
  { image: project1Image2, alt: "Project 1 - Image 2" },
  { image: project1Image5, alt: "Project 1 - Image 5" },
  { image: project2Image2, alt: "Project 2 - Image 2" },
  { image: project2Image4, alt: "Project 2 - Image 4" },
  { image: project2Image7, alt: "Project 2 - Image 7" },
  { image: project3Image3, alt: "Project 3 - Image 3" },
  { image: project3Image4, alt: "Project 3 - Image 4" },
  { image: project3Image5, alt: "Project 3 - Image 5" },
  { image: project3Image7, alt: "Project 3 - Image 7" },
  { image: project3Image6, alt: "Project 3 - Image 6" },
  { image: project3Image9, alt: "Project 3 - Image 9" },
  { image: project4Image2, alt: "Project 4 - Image 2" },
  { image: project4Image4, alt: "Project 4 - Image 4" },
  { image: project5Image3, alt: "Project 5 - Image 3" },
  { image: project5Image2, alt: "Project 5 - Image 2" },
  { image: project5Image6, alt: "Project 5 - Image 6" },
  { image: project5Image7, alt: "Project 5 - Image 7" },
  { image: project5Image9, alt: "Project 5 - Image 9" },
  { image: project6Image4, alt: "Project 6 - Image 4" },
  { image: project6Image5, alt: "Project 6 - Image 5" },
  { image: project6Image6, alt: "Project 6 - Image 6" },
  { image: project6Image7, alt: "Project 6 - Image 7" },
  { image: project7Image2, alt: "Project 7 - Image 2" },
  { image: project7Image5, alt: "Project 7 - Image 5" },
  { image: project8Image9, alt: "Project 8 - Image 9" },
  { image: project8Image6, alt: "Project 8 - Image 6" },
  { image: project8Image5, alt: "Project 8 - Image 5" },
  { image: project8Image4, alt: "Project 8 - Image 4" },
  { image: project8Image3, alt: "Project 8 - Image 3" },
  { image: project8Image2, alt: "Project 8 - Image 2" },
];



const ServicesHero = () => (
 <section className="ServicesHero">
      <div className="ServicesHero__MediaWrap">
        <div className="ServicesHero__HeroImg">
          <img src={heroImage} alt="Services Hero" />
        </div>
        <div className="ServicesHero__Popup ServicesHero__Popup--right">
          <div className="ServicesHero__PopupContent">
            <h1 className="ServicesHero__Title">Services</h1>
            <p>
              We at KOTTOOR master this art with our passion & creativity
              towards our fitouts. Every project in interiors is completely
              different from the other. Although logically there are only walls,
              ceilings, and floors in every single project, when you look at it
              with an eye for detail, they are two different worlds.
            </p>
            <p>
              The color combinations, finishes, design, corporate identity,
              fit-out style, timelines, budgets, building regulations, and local
              authority regulations make it different. We have equipped
              ourselves with a team of professional architects, designers, and
              engineers who are well-trained and experienced to handle the
              project from concept to completion.
            </p>
            <p>
              We use different finishes for different projects based on the
              budget and corporate culture of the company, ranging from
              wallpaper, wall paints, special paints, wall paneling, and much
              more. Similarly, our ceiling offerings include standard gypsum,
              plain metal, flat plasterboard, and even open painted ceilings.
            </p>
            <p>
              Our flooring options range from marble, vitrified, solid parquet,
              semi-solid parquet, laminated parquet, carpet, and more. We also
              provide gypsum, glass, and collapsible/demountable partitions.
            </p>
            <p>
              We provide turnkey design & build solutions. For inquiries related
              to this segment, please feel free to contact us at{" "}
              <a href="mailto:sales@kottoor.com">sales@kottoor.com</a>.
            </p>
          </div>
        </div>
      </div>
    </section>
  );


const ArchitectureSection = () => (
  <section className="architecture-section">
    <div className="architecture-images">
      <img src={project1Image2} alt="Architecture Image 1" className="image1" />
      <img src={project1Image5} alt="Architecture Image 2" className="image2" />
    </div>
    <div className="architecture-text">
      <h2>Joinery</h2>
      <p>
        To shore up our desire to artistry, we have set up our state of the art
        joinery factory in Dubai. It is a very well equipped state of-the-art
        facility with machineries, tools and equipment of the latest technology.
        We produce high quality joinery which are designed in its finest detail
        and provide very good quality of finish. We work in various material
        finishes both natural and artificial. We produce both fire rated and non
        fire rated products. We produce joinery for all commercial projects like
        offices, hotels, hospital, laboratories, auditorium and restaurants. We
        have the leverage to design custom made design in various sizes. Being
        established for the last 20 years we are constantly working on upgrading
        the quality of work and design. We are committed to deliver quality
        products within the defined time lines of the projects.
      </p>
    </div>
  </section>
);

const EngineeringSection = () => (
  <section className="engineering-section">
    <div className="engineering-text">
      <h2>Space Planning</h2>
      <p>
        In the process of space planning, we at Kottoor maintain a harmony
        between the client requirement and actual space function and aesthetic
        both. Space planning in interior design is the process of organizing
        furniture, fixtures, and other elements within a space to ensure
        functionality, efficiency, and aesthetic appeal. It starts with
        understanding the space’s dimensions and intended purpose, followed by
        creating a layout that maximizes usability and comfort. Key
        considerations include circulation paths, zoning for different
        activities, and the integration of technology. Space planning also
        involves ensuring accessibility and ergonomics while maintaining a
        cohesive style. In fitouts, it’s essential for customizing interiors to
        meet specific needs, enhancing both functionality and visual appeal.
        Overall, space planning optimizes space utilization to create harmonious
        and practical environments.
      </p>
    </div>
    <div className="engineering-images">
      <img src={project3Image3} alt="Engineering Image 1" className="image1" />
      <img src={project3Image4} alt="Engineering Image 2" className="image2" />
    </div>
  </section>
);


const ArchitectureSection2 = () => (
  <section className="architecture-section">
    <div className="architecture-images">
      <img src={project7Image2} alt="Architecture Image 1" className="image1" />
      <img src={project7Image5} alt="Architecture Image 2" className="image2" />
    </div>
    <div className="architecture-text">
      <h2>Turnkey Fitouts</h2>
      <p>
        Turnkey Fitout is a process of providing the consumer with end to end
        services. Here we follow a complete process from design to build. This
        also includes execution of works with all trades like flooring, ceiling,
        painting works, joinery, MEP works, related testing and commissioning
        along with Handover. Obtaining permits and closing projects to obtain
        completion certificate is part of the process.
      </p>
    </div>
  </section>
);



const EngineeringSection2 = () => (
  <section className="engineering-section">
    <div className="engineering-text">
      <h2>Furniture</h2>
      <p>
        Various products under One Roof! We welcome you to experience our new
        designs and collection of furniture. We are designers and stockiest of a
        variety of products, which are available with us throughout the year.
        You will find a variety of products along with workstations, managers
        desk, conference tables sofas, designer chairs.
      </p>
    </div>
    <div className="engineering-images">
      <img src={project6Image5} alt="Engineering Image 1" className="image1" />
      <img
        src={project6Image6}
        mage4
        alt="Engineering Image 2"
        className="image2"
      />
    </div>
  </section>
);


const ServicesFeaturedProjects = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    beforeChange: (current, next) => setSelectedImage(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="services-featured-projects">
      <h2>Featured Projects</h2>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div
            key={index}
            className={`services-slide ${
              selectedImage === index ? "selected" : ""
            }`}
            onClick={() => setSelectedImage(index)}
          >
            <img src={project.image} alt={project.alt} />
            <div className="services-overlay">
              <div className="services-circle">DRAG</div>
            </div>
            {selectedImage === index && (
              <a href="/portfolio">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="services-search-icon"
                />
              </a>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};


const OurClients = () => (
  <section className="our-clients">
    <h2 className="clients-heading">Our Clients</h2>
    <div className="clients-row">
      <span className="client-name">Superior Extrusions Inc.</span>
      <span className="client-name">Embers Credit Union</span>
      <span className="client-name">Lincoln Consolidated Schools</span>
      <span className="client-name highlight">The Delft Bistro</span>
    </div>
    <div className="clients-row">
      <span className="client-name highlight">Brighton Area Schools</span>
      <span className="client-name">Barrel + Beam</span>
      <span className="client-name">Cliffs Natural Resources</span>
    </div>
    <div className="clients-row">
      <span className="client-name">Rippling River Resort Campground</span>
      <span className="client-name">Hannahville Indian Community</span>
      <span className="client-name highlight">Meridian Public Schools</span>
      <span className="client-name">George Young Recreation Complex</span>
    </div>
  </section>
);

const WorkWithUs2 = () => (
  <section className="work-with-us">
    <div className="work-with-us-container">
      <div className="work-with-us-image">
        <img src={join} alt="Join with Us" />
      </div>
      <div className="work-with-us-content">
        <h2 className="work-with-us-heading">Contracting</h2>
        <p className="work-with-us-text">
          ALL UNDER ONE ROOF!
          <br />
          “Design, approvals, fitout, build, construction, completion process
          and certificates”
        </p>
        <p className="work-with-us-text">
          This segment provides a unique service of all process and facilities
          available under one roof which will relieve the client from various
          point of contacts to a single point of contact. 
        </p>
        <p className="work-with-us-text">
          For enquiries related to this segment, please feel free to contact us
          on 0555891992.
        </p>
        <a href="/contact" className="work-with-us-button">
          Contact Us
        </a>
      </div>
    </div>
  </section>
);

const ServicesPage = () => (
  <div>
    <ServicesHero />
    <ArchitectureSection />
    <EngineeringSection />

    <ServicesFeaturedProjects />
    <ArchitectureSection2 />
    <EngineeringSection2 />
    <OurClients />
    <WorkWithUs2 />
  </div>
);

export default ServicesPage;
