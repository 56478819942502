import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faLinkedin,
  faInstagram,
  faTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../images/logo_removebg.png";
import heroVideo from "../video/about_hero.mp4";
import heroImage from "../images/about us/about_hero_image.jpg";
import main1 from "../images/about us/main.jpg";
import main2 from "../images/about us/main2 - Copy (2).jpg";
import main3 from "../images/about us/pantry - Copy.jpg";
import join from "../images/about us/Join with us.jpg";
import heroImage3 from "../images/about us/process.jpg";
import heroImage4 from "../images/about us/about_hero_image.jpg";
import "./AboutPage.css";

const AboutUsPage = () => {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const [playVideo, setPlayVideo] = React.useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  React.useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isNavOpen]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setPlayVideo(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {/* About Hero */}
      <section className="AboutHero__StyledAboutHero">
        <div className="HeroMedia__VideoWrap">
          {playVideo ? (
            <video autoPlay loop muted playsInline className="HeroMedia__Video">
              <source src={heroVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={heroImage} alt="Hero" className="HeroMedia__Image" />
          )}
        </div>
      </section>

      {/* About Story */}
      <section className="about-story">
        <div className="container grid">
          <h1 className="story-heading">Our Story</h1>
          <div className="story-content">
            <p>
              Kottoor is based in Dubai since 2004 and specializes in Turnkey
              Interior Fitout projects. We design and build spaces with both
              form and function in mind.
            </p>
            <p>
              We have equipped with our in-house joinery facility along with a
              team to execute the project. This helps us control both quality
              and time.
            </p>
          </div>
        </div>
      </section>

      {/* About Images */}
      <div className="about-story-images">
        <div className="main-image">
          <img alt="Main space" src={main1} />
        </div>
        <div className="angled-image angled-image-1">
          <img alt="Space planning" src={main2} />
        </div>
        <div className="angled-image angled-image-2">
          <img alt="Pantry area" src={main3} />
        </div>
      </div>

      {/* Our Mission */}
      <div className="our-mission">
        <h1>Our Strength</h1>
        <div className="mission-grid">
          <div className="mission-column">
            <h3>Phase 1: Project Briefing & Design Finalization</h3>
            <p>
              Fit out in itself is a process. Being a turnkey interior fit out
              specialist, our process of work begins from the briefing of the
              project given to us by the client to the draft layout followed by
              design finalization. Mood boards with samples and design approval
              lead us to the finalization of the scope.
            </p>
          </div>
          <div className="mission-column">
            <h3>Phase 2: Approval & Permitting</h3>
            <p>
              This enables us to submit our bid. We would call it the first
              phase of the project. The second phase is the approval phase
              wherein we obtain permits from relevant authorities to start works
              at site.
            </p>
          </div>
          <div className="mission-column">
            <h3>Phase 3: Execution & Handover</h3>
            <p>
              The third phase is site works as per the schedule, in line with
              the approved samples and design. Upon completion of site works, we
              move to the fourth phase of snagging and obtaining completion
              certificates followed by the final phase to hand over the
              completed project to the client.
            </p>
          </div>
        </div>
      </div>

      {/* Our Process */}
      <div className="our-process">
        <div className="text-content">
          <h1>Our Process</h1>
          <p>
            Fit out in itself is a process. Being a turnkey interior fit out
            specialist, our process of work begins from the briefing of the
            project given to us by the client to the draft layout followed by
            design finalization. Mood boards with samples and design approval
            lead us to finalization of the scope. This enables us to submit our
            bid. We would call it the first phase of the project.
          </p>
          <p>
            Second phase being the approval phase wherein we obtain permits from
            relevant authorities to start works at site. Third phase is site
            works as per the schedule, in line with the approved samples and
            design. Upon completion of site works, we move to the fourth phase
            of snagging and obtaining completion certificates followed by the
            final phase to handover the completed project to the client.
          </p>
        </div>
        <div className="image-content">
          <img
            className="main-image"
            src={heroImage3}
            alt="Person working on architectural plans"
          />
          <img
            className="secondary-image"
            src={heroImage4}
            alt="Field view from above"
          />
        </div>
      </div>

      {/* Services Section */}
      <div className="services-section">
        <h1 className="services-title">Our Services</h1>
        <div className="services-bars">
          <div className="service-bar green-bar">
            <span>Joinery</span>
          </div>
          <div className="service-bar light-green-bar">
            <span>Space Planning</span>
          </div>
          <div className="service-bar mint-bar">
            <span>Turnkey Fitouts</span>
          </div>
          <div className="service-bar blue-bar">
            <span>MEP Contracting</span>
          </div>
          <div className="service-bar light-blue-bar">
            <span>Acoustic Solution</span>
          </div>
          <div className="service-bar sky-blue-bar">
            <span>Project Management</span>
          </div>
        </div>
      </div>

      {/* Work With Us */}
      <section className="work-with-us">
        <div className="work-with-us-container">
          <div className="work-with-us-image">
            <img src={join} alt="Team working" />
          </div>
          <div className="work-with-us-content">
            <h2 className="work-with-us-heading">Project We Undertake</h2>
            <ul className="work-with-us-text">
              <li>Offices</li>
              <li>Hospitals</li>
              <li>Clinics</li>
              <li>Laboratories</li>
              <li>Banks</li>
              <li>Retail Outlets</li>
              <li>Auditorium</li>
              <li>Restaurants</li>
              <li>Exotic Spas</li>
              <li>Hotels & Furnishing</li>
              <li>Schools & Universities</li>
              <li>Parks & Pergolas</li>
            </ul>
            <a href="/contact" className="work-with-us-button">
              Contact Us
            </a>
          </div>
        </div>
      </section>

      {/* Our Clients */}
      <section className="our-clients">
        <h2 className="clients-heading">Our Clients</h2>
        <div className="clients-row">
          <span className="client-name">Wyandotte Public Schools</span>
          <span className="client-name">Northern Michigan University</span>
          <span className="client-name highlight">
            Peter White Public Library
          </span>
          <span className="client-name">Alpena Public Schools</span>
        </div>
        <div className="clients-row">
          <span className="client-name">Harrison Community Schools</span>
          <span className="client-name highlight">
            Michigan Technological University
          </span>
          <span className="client-name">
            Marquette County Medical Care Facility
          </span>
          <span className="client-name">
            Elkton-Pigeon Bay Port Lakers Schools
          </span>
        </div>
        <div className="clients-row">
          <span className="client-name">Rippling River Resort Campground</span>
          <span className="client-name">Hannahville Indian Community</span>
          <span className="client-name highlight">Meridian Public Schools</span>
          <span className="client-name">George Young Recreation Complex</span>
        </div>
      </section>
    </div>
  );
};

export default AboutUsPage;
