import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import AboutUsPage from "./components/AboutPage";
import ServicesPage from "./components/ServicesPage";
import Footer from "./components/Footer";
import ProjectPage1 from "./components/ProjectPage1";
import ProjectPage2 from "./components/ProjectPage2";
import ProjectPage3 from "./components/ProjectPage3";
import ProjectPage4 from "./components/ProjectPage4";
import ProjectPage5 from "./components/ProjectPage5";
import ProjectPage6 from "./components/ProjectPage6";
import ProjectPage7 from "./components/ProjectPage7";
import ProjectPage8 from "./components/ProjectPage8";
import ContactPage from "./components/ContactUsPage";
import SpotlightSection from "./components/SpotlightSection";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/portfolio" element={<SpotlightSection />} />
          <Route path="/ProjectPage1" element={<ProjectPage1 />} />
          <Route path="/ProjectPage2" element={<ProjectPage2 />} />
          <Route path="/ProjectPage3" element={<ProjectPage3 />} />
          <Route path="/ProjectPage4" element={<ProjectPage4 />} />
          <Route path="/ProjectPage5" element={<ProjectPage5 />} />
          <Route path="/ProjectPage6" element={<ProjectPage6 />} />
          <Route path="/ProjectPage7" element={<ProjectPage7 />} />
          <Route path="/ProjectPage8" element={<ProjectPage8 />} />
          {/* Add more routes as needed */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
