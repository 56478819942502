import React from "react";
import "./ProjectPage.css";

import projectphoto1 from "../images/projects/Project4/1.jpg";
import projectphoto2 from "../images/projects/Project4/2.jpg";
import projectphoto3 from "../images/projects/Project4/3.jpg";
import projectphoto4 from "../images/projects/Project4/4.jpg";
import projectphoto5 from "../images/projects/Project4/5.jpg";

const ProjectHero = () => {
  return (
    <section className="project-hero">
      <div className="hero-content">
        <h1>NBF Bank Branch Interiors</h1>
        <span className="location">Dubai, UAE</span>
        <p>
          The NBF Bank project showcases our expertise in creating a refined and
          functional banking environment. The design emphasizes customer
          experience and operational efficiency, integrating modern aesthetics
          with the bank’s corporate identity. Located in the heart of Dubai,
          this branch reflects the brand’s commitment to innovation and customer
          satisfaction.
        </p>
      </div>
      <div className="hero-image-container">
        <img
          src={projectphoto1}
          alt="NBF Bank Branch Interiors"
          className="hero-image"
        />
      </div>
    </section>
  );
};

const FacilityMasterplan = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The master plan for NBF Bank involved creating a space that balances
          security with customer comfort. The layout strategically positions
          teller stations, private offices, and waiting areas to ensure smooth
          customer flow while maintaining privacy and security. The use of
          natural light and open spaces enhances the overall ambiance, making
          the branch both welcoming and efficient.
        </p>
      </div>
    </section>
  );
};

const ProjectSection = () => {
  return (
    <section className="section-sc-1vjgz5n-0 eeeNzp">
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu">
        <div className="ratio-img-wrapper-sc-8qesjk-1 project-image-sc-di9xrj-0">
          <img
            alt="Modern Teller Stations"
            className="ratio-img-sc-8qesjk-0 dwEpAz"
            src={projectphoto2}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-1">
          The modern teller stations are designed to optimize service efficiency
          while ensuring customer privacy. With clean lines and ergonomic
          design, these stations blend functionality with the bank’s sleek,
          modern aesthetic.
        </p>
      </div>
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu reverse">
        <div className="ratio-img-wrapper-sc-8qesjk-2 project-image-sc-di9xrj-0">
          <img
            alt="Client Meeting Rooms"
            className="ratio-img-sc-8qesjk-0 dwEpAz2"
            src={projectphoto3}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-2">
          The client meeting rooms are equipped with state-of-the-art
          technology, offering a professional yet comfortable setting for client
          consultations. The design incorporates soundproofing and sophisticated
          decor to ensure a seamless and private meeting experience.
        </p>
      </div>
    </section>
  );
};

const FacilityMasterplan2 = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          Additionally, the branch includes a spacious lobby with comfortable
          seating, and digital kiosks for quick transactions. The use of ambient
          lighting and modern finishes ensures that the environment feels both
          upscale and approachable, aligning with NBF Bank’s brand values.
        </p>
      </div>
    </section>
  );
};

const UniqueSection = () => {
  return (
    <section className="uniqueBeNxCw">
      <div className="uniqueEBZEyY">
        <div className="uniqueECjdAA">
          <h2 className="uniqueFLhVRG">Explore More About Us</h2>
        </div>
        <div className="uniqueWOhqI">
          <div className="uniqueDZUreZ">
            <a href="/services" aria-label="Services">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto4}
                    alt="Our Services"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Discover Our Services</h3>
                <span className="uniqueJxeAbl">View Services</span>
              </div>
            </a>
          </div>
          <div className="uniqueDZUreZ">
            <a href="/about" aria-label="About Us">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto5}
                    alt="About Us"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Learn About Our Journey</h3>
                <span className="uniqueJxeAbl">Who We Are</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectPage4 = () => {
  return (
    <>
      <ProjectHero />
      <FacilityMasterplan />
      <ProjectSection />
      <FacilityMasterplan2 />
      <UniqueSection />
    </>
  );
};

export default ProjectPage4;
