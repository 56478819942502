import React from "react";
import "./ProjectPage.css";

import projectphoto1 from "../images/projects/Project2/1.jpg";
import projectphoto2 from "../images/projects/Project2/3.jpg";
import projectphoto3 from "../images/projects/Project2/4.jpg";
import projectphoto4 from "../images/projects/Project2/5.jpg";
import projectphoto5 from "../images/projects/Project2/6.jpg";

const ProjectHero = () => {
  return (
    <section className="project-hero">
      <div className="hero-content">
        <h1>UKCBC Campus Interiors</h1>
        <span className="location">Dubai, UAE</span>
        <p>
          The UKCBC campus in Dubai was designed with the student experience in
          mind. Our approach focused on creating a learning environment that is
          not only functional but also inspiring. Every corner of the campus
          reflects modern educational values, ensuring students have access to a
          space that encourages collaboration, creativity, and focus.
        </p>
      </div>
      <div className="hero-image-container">
        <img
          src={projectphoto1}
          alt="UKCBC Campus Dubai"
          className="hero-image"
        />
      </div>
    </section>
  );
};

const FacilityMasterplan = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The master plan for UKCBC involved the strategic placement of various
          learning zones throughout the campus. From open study areas to private
          classrooms, the design maximizes natural light and provides
          flexibility for different teaching and learning methods. The
          integration of state-of-the-art technology further supports the
          institution’s commitment to academic excellence.
        </p>
      </div>
    </section>
  );
};

const ProjectSection = () => {
  return (
    <section className="section-sc-1vjgz5n-0 eeeNzp">
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu">
        <div className="ratio-img-wrapper-sc-8qesjk-1 project-image-sc-di9xrj-0">
          <img
            alt="Student Lounge Area"
            className="ratio-img-sc-8qesjk-0 dwEpAz"
            src={projectphoto2}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-1">
          The student lounge area is a vibrant space where students can relax,
          socialize, and collaborate. With comfortable seating, vibrant colors,
          and ample natural light, it’s designed to be a hub of student life on
          campus.
        </p>
      </div>
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu reverse">
        <div className="ratio-img-wrapper-sc-8qesjk-2 project-image-sc-di9xrj-0">
          <img
            alt="Advanced Computer Labs"
            className="ratio-img-sc-8qesjk-0 dwEpAz2"
            src={projectphoto3}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-2">
          The advanced computer labs are equipped with the latest technology,
          providing students with the tools they need to excel in their studies.
          The labs are designed to be both functional and comfortable, with
          ergonomic seating and ample workspace for group projects.
        </p>
      </div>
    </section>
  );
};

const FacilityMasterplan2 = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The campus also features dedicated quiet zones for individual study,
          ensuring that students have the perfect environment to focus on their
          academic work. These areas are designed with minimal distractions and
          are equipped with comfortable seating and plenty of natural light to
          create a conducive study atmosphere.
        </p>
      </div>
    </section>
  );
};

const UniqueSection = () => {
  return (
    <section className="uniqueBeNxCw">
      <div className="uniqueEBZEyY">
        <div className="uniqueECjdAA">
          <h2 className="uniqueFLhVRG">Explore More About Us</h2>
        </div>
        <div className="uniqueWOhqI">
          <div className="uniqueDZUreZ">
            <a href="/courses" aria-label="Courses">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto4}
                    alt="Our Courses"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Discover Our Services</h3>
                <span className="uniqueJxeAbl">View Services</span>
              </div>
            </a>
          </div>
          <div className="uniqueDZUreZ">
            <a href="/about" aria-label="About UKCBC">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto5}
                    alt="About UKCBC"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Learn About Our Journey</h3>
                <span className="uniqueJxeAbl">Who We Are</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectPage2 = () => {
  return (
    <>
      <ProjectHero />
      <FacilityMasterplan />
      <ProjectSection />
      <FacilityMasterplan2 />
      <UniqueSection />
    </>
  );
};

export default ProjectPage2;
